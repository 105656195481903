
import Vue from "vue";
import dayjs from "dayjs";
import userStore from "@/store/modules/user";
export default Vue.extend({
  components: {},
  props: {
    // 下载模板示例 https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/template/speedbiz/服务项目导入.xlsx
    templateUrl: {
      type: String,
      required: true
    },
    // 导入接口示例 /api/filecloud/importServiceItems
    actionUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploadState: false,
      dialogVisible: false,
      upPendingFlag: false,
      dangerFailure: false,
      disableFlag: true,
      successBody: false,
      successNum: undefined,
      failNum: undefined,
      diaTitle: "批量导入",
      compeletTime: undefined,
      allData: undefined,
      headers: {
        userAuthToken: ""
      },
      resultFileUrl: "", //下载导入失败文件路径
      fileList: []
    };
  },
  created() {
    this.headers.userAuthToken = userStore.state.token;
  },
  methods: {
    handleOpen() {
      this.uploadState = true;
    },
    handleClose() {
      this.uploadState = false;
    },
    submitUpload() {
      const ref: any = this.$refs.upload;
      ref.submit();
    },
    handleRemove(file: any, fileList: any) {
      console.log(file, fileList);
      this.disableFlag = true;
    },
    handlePreview(file: any) {
      console.log(file, "preview");
    },
    errorEvent() {
      console.log("上传失败");
      this.dangerFailure = true;
      this.upPendingFlag = false;
      this.disableFlag = true;
      this.fileList = [];
    },
    upPending() {
      console.log("上传中");
      this.dangerFailure = false;
      this.upPendingFlag = true;
    },
    upSuccess(response, file, fileList) {
      this.upPendingFlag = false;
      console.log(response, file, fileList, "response, file, fileList,上传成功");
      if (response.data) {
        this.diaTitle = "批量导入结果";
        this.successBody = true;
        this.disableFlag = false;
        const { failedLines, resultFilePath, totalLines } = response.data;
        this.successNum = totalLines - failedLines;
        this.allData = totalLines;
        this.failNum = failedLines;
        this.compeletTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
        this.resultFileUrl = resultFilePath;
      } else {
        this.dangerFailure = true;
        this.fileList = [];
        this.disableFlag = true;
      }
    },
    changeUpload(file: any, fileList: any) {
      console.log("上传文件", file, fileList);
      if (fileList.length > 0 && file.status == "ready") {
        this.disableFlag = false;
      }
    },
    cancelOrContinue() {
      if (this.diaTitle == "批量导入") {
        this.uploadState = false;
        this.fileList = [];
        this.disableFlag = true;
        this.dialogVisible = false;
        this.upPendingFlag = false;
        this.dangerFailure = false;
        this.successBody = false;
      } else {
        console.log("继续导入");
        this.fileList = [];
        this.upPendingFlag = false;
        this.dangerFailure = false;
        this.disableFlag = true;
        this.successBody = false;
        this.diaTitle = "批量导入";
      }
    }
  }
});
